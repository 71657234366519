const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
}

class GatherLogoAnimation extends HTMLElement {
  constructor() {
    super();
    this.letterG = this.querySelector('[data-anchor="letter-g"]');
    this.letterA = this.querySelector('[data-anchor="letter-a"]');
    this.letterT = this.querySelector('[data-anchor="letter-t"]');
    this.letterH = this.querySelector('[data-anchor="letter-h"]');
    this.letterE = this.querySelector('[data-anchor="letter-e"]');
    this.letterR = this.querySelector('[data-anchor="letter-r"]');
    this.about = document.querySelector('#about');
    this.navItem1 = document.querySelector('.nav-item--1');
    this.navItem2 = document.querySelector('.nav-item--2');
    this.pinEl = this.querySelector('.logo-animation-pin');
    this.scrollButton = document.querySelector('.scroll-button');
    this.centeredText = document.querySelectorAll('.centered-text');
    this.centeredTextArray = Array.from(this.centeredText);
    this.map = document.querySelector('.map');
    this.slider = document.querySelector('.main-carousel');
    this.content = document.querySelector('.content-container');
    this.site1 = document.querySelector('.site--1');
    this.site2 = document.querySelector('.site--2');
    this.site3 = document.querySelector('.site--3');
    this.logo = this.querySelector('h1');
    this.body = document.querySelector('body');
    this.viewport = {
      width: visualViewport.width,
      height: visualViewport.height
    }

    setTimeout(() => {
      this.classList.add('is-ready');
    }, 1000);

    const AllGsapStuff = debounce((ev) => {

      gsap.to(this.logo, {
        opacity: 0,
        scrollTrigger: {
          trigger: this.about,
          start: 'top 20%',
          end: `+=300`,
          scrub: true
        },
        immediateRender: false
      });

      gsap.to(this.navItem1, {
        opacity: 0,
        scrollTrigger: {
          trigger: this.about,
          start: 'top 20%',
          end: "+=200",
          scrub: true
        },
        immediateRender: false
      });

      gsap.to(this.navItem2, {
        opacity: 0,
        scrollTrigger: {
          trigger: this.about,
          start: 'top 20%',
          end: "+=200",
          scrub: true
        },
        immediateRender: false
      });

      gsap.to(this.site1, {
        x: '-220%',
        y: '-80%',
        opacity: 1,
        scrollTrigger: {
          trigger: this.map,
          start: 'center bottom',
          end: "+=300",
          scrub: true
        },
        immediateRender: false
      });
  
      gsap.to(this.site2, {
        x: '120%',
        y: '-14%',
        opacity: 1,
        scrollTrigger: {
          trigger: this.map,
          start: 'center bottom',
          end: "+=300",
          scrub: true
        },
        immediateRender: false
      });
  
      gsap.to(this.site3, {
        x: '-180%',
        y: '85%',
        opacity: 1,
        scrollTrigger: {
          trigger: this.map,
          start: 'center bottom',
          end: "+=300",
          scrub: true
        },
        immediateRender: false
      });
  
      this.centeredTextArray.forEach(item => {
        gsap.to(item, {
          opacity: 0,
          scrollTrigger: {
            trigger: item,
            start: 'bottom 40%',
            end: "+=250",
            scrub: true
          },
          immediateRender: false
        });
      });
  
      ScrollTrigger.create({
        trigger: this.content,
        start: "top 10%",
        endTrigger: this.slider,
        end: "top 10%",
        onToggle: (self) => {
          if (self.isActive) {
            this.body.classList.add('dark');
          } else {
            this.body.classList.remove('dark');
          }
        }
      });
  
      gsap.to(this.scrollButton, {
        opacity: 0,
        scrollTrigger: {
          trigger: this.content,
          start: 'top center',
          end: "+=200",
          scrub: true
        },
        immediateRender: false
      });
  
      gsap.to(this.letterG, {
        top: 0,
        left: '50%',
        x: visualViewport.width >= 640 ? '-175%' : '-138%',
        y: '0%',
        height: visualViewport.width >= 640 ? '25px' : '18px',
        width: '70px', 
        scrollTrigger: {
          trigger: this.pinEl,
          pin: true,
          start: 'top top',
          end: `+=${visualViewport.height / 2}`,
          scrub: true
        },
        immediateRender: false
      });
  
      gsap.to(this.letterA, {
        top: 0,
        left: '50%',
        x: visualViewport.width >= 640 ? '-125%' : '-100%',
        y: '0%',
        height: visualViewport.width >= 640 ? '25px' : '18px',
        width: '70px',
        scrollTrigger: {
          trigger: this.pinEl,
          pin: true,
          start: 'top top',
          end: `+=${visualViewport.height / 2}`,
          scrub: true
        },
        immediateRender: false
      });
  
      gsap.to(this.letterT, {
        top: 0,
        left: '50%',
        x: visualViewport.width >= 640 ? '-77%' : '-67%',
        y: '0%',
        height: visualViewport.width >= 640 ? '25px' : '18px',
        width: '70px',
        scrollTrigger: {
          trigger: this.pinEl,
          pin: true,
          start: 'top top',
          end: `+=${visualViewport.height / 2}`,
          scrub: true
        },
        immediateRender: false
      });
  
      gsap.to(this.letterH, {
        top: 0,
        left: '50%',
        x: visualViewport.width >= 640 ? '-25%' : '-30%',
        y: '0%',
        height: visualViewport.width >= 640 ? '25px' : '18px',
        width: '70px',
        scrollTrigger: {
          trigger: this.pinEl,
          pin: true,
          start: 'top top',
          end: `+=${visualViewport.height / 2}`,
          scrub: true
        },
        immediateRender: false
      });
  
      gsap.to(this.letterE, {
        top: 0,
        left: '50%',
        x: visualViewport.width >= 640 ? '25%' : '8%',
        y: '0%',
        height: visualViewport.width >= 640 ? '25px' : '18px',
        width: '70px',
        scrollTrigger: {
          trigger: this.pinEl,
          pin: true,
          start: 'top top',
          end: `+=${visualViewport.height / 2}`,
          scrub: true
        },
        immediateRender: false
      });
  
      gsap.to(this.letterR, {
        top: 0,
        left: '50%',
        x: visualViewport.width >= 640 ? '75%' : '43%',
        y: '0%',
        height: visualViewport.width >= 640 ? '25px' : '18px',
        width: '70px',
        scrollTrigger: {
          trigger: this.pinEl,
          pin: true,
          start: 'top top',
          end: `+=${visualViewport.height / 2}`,
          scrub: true
        },
        immediateRender: false
      });
    }, 250)

    // init
    AllGsapStuff()

    window.addEventListener('resize', AllGsapStuff);
  }
}

customElements.define('gather-logo-animation', GatherLogoAnimation);

class ParallaxContainer extends HTMLElement {
  constructor() {
    super();
    let el = this.querySelector('.parallax');

    setTimeout(() => {
      this.classList.add('is-ready');
    }, 500);

    function updateParallax() {
      const scrollY = window.scrollY;
      const translateY = scrollY * 0.4;
      // const opacity = 1 - scrollY / visualViewport.height;
  
      el.style.transform = `translate3d(0, ${translateY}px, 0)`;
      // el.style.opacity = opacity;
    }

    window.addEventListener('scroll', updateParallax);
  }
}

customElements.define('parallax-container', ParallaxContainer);

import Flickity from "flickity";

const mainCarousel = document.querySelector('.main-carousel');

if (mainCarousel) {
  var flkty = new Flickity( '.main-carousel', {
    cellAlign: "left",
    autoPlay: 3000,
    pauseAutoPlayOnHover: true,
    imagesLoaded: true,
    setGallerySize: false,
    adaptiveHeight: false,
    wrapAround: true,
    prevNextButtons: true,
    pageDots: false,
    selectedAttraction: 0.15,
    friction: 0.8,
    arrowShape: { 
      x0: 10,
      x1: 60, y1: 50,
      x2: 60, y2: 0,
      x3: 60
    }
  }); 
}

document.querySelectorAll('[data-target]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('data-target')).scrollIntoView({
          behavior: 'smooth'
      });
  });
});

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
      });
  });
});